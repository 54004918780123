<template>
    <div class="container">
        <div class="row align-items-center" style="height: 100vh;">
            <div class="col-12 py-4">
                <div class="col-xl-5 col-lg-6 col-md-7 col-12 mx-auto logo text-center">
                    <img src="../assets/img/brand/logo.png" />
                </div>
                <Forgot @do-reset="resetPassword" :status="resetStatus" v-if="showPasswordReset"
                    @toggle-login-form="toggleLogin"  class="col-xl-5 col-lg-6 col-md-7 col-12 mx-auto"/>
                <LoginForm @do-login="login" v-show="showLoginForm" @toggle-password-reset="togglePasswordReset"
                    @toggle-signup="toggleSignUp" :errorMessage="{ getError }" class="col-xl-5 col-lg-6 col-md-7 col-12 mx-auto">
                    <p v-if="getInformation && getInformation.signUp">
                        {{ getInformation.signUp.message }}
                    </p>
                    <p v-if="getError && getError.login">Invalid email/password</p>
                </LoginForm>
                <SignUpForm @do-signup="signUp" :status="signUpStatus" v-if="showSignUpForm"
                    @toggle-login-form="toggleLogin" class="col-xl-7 col-lg-10 col-md-10 col-12 mx-auto" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LoginForm from '@/components/auth/Login.vue'
import SignUpForm from '@/components/auth/Register.vue'
import Forgot from '@/components/auth/Forgot.vue'

export default {
    name: 'Auth',
    components: {
        Forgot,
        LoginForm,
        SignUpForm,
    },
    data() {
        return {
            showLoginForm: false,
            showSignUpForm: true,
            showPasswordReset: false,
            signUpStatus: {},
            resetStatus: {},
            errorStatus: {},
        }
    },
    computed: {
        ...mapGetters(['getError', 'getInformation']),
    },
    methods: {
        toggleLogin() {
            this.showLoginForm = true
            this.showSignUpForm = false
            this.showPasswordReset = false
        },
        toggleSignUp() {
            this.showLoginForm = false
            this.showSignUpForm = true
            this.showPasswordReset = false
        },
        togglePasswordReset() {
            this.showLoginForm = false
            this.showSignUpForm = false
            this.showPasswordReset = true
        },
        async login(data) {
            try {
                await this.$store.dispatch('login', {
                    email: data.email,
                    password: data.password,
                })
            } catch (error) {
                console.log('fel: ', error)
            }
        },
        async signUp(data) {
            await this.$store.dispatch('signup', {
                email: data.email,
                password: data.password,
                title: data.title,
                firstName: data.firstName,
                lastName: data.lastName,
                companyName: data.companyName,
                organisationNr: data.organisationNr,
                phoneNr: data.phoneNr,
                address: data.address,
                subscription: data.subscription,
            })
            this.signUpStatus = this.getError?.signUp || this.getInformation?.signUp
        },
        async resetPassword(data) {
            await this.$store.dispatch('resetPassword', { email: data.email })
            this.resetStatus =
                this.getError?.resetPassword || this.getInformation?.resetPassword
        },
    },
    watch: {
        getInformation(newInformation) {
            newInformation?.signUp && this.toggleLogin()
        },
    },
}
</script>


<style>
/* handling img of login & Register */
.logo img {
    width: 460px;
    max-width: 95%;
}
</style>
